<template>
  <div>
    <currentLocation :sibItem="sibItem"></currentLocation>
    <div class="outer">
      <img :src="bannerImg" alt="" class="bigPicture" />
      <div class="box" v-if="newList.length > 0">
        <div class="tipOne">{{ $t("scientificAchievements.SA_tipOne") }}</div>
        <div
          class="newsItem"
          v-for="(item, index) in newList"
          :key="index"
          @click="articleDetail(item)"
        >
          <img :src="item.image" alt="" />
          <div class="itemCont">
            <div class="title">{{ item.title }}</div>
            <div class="cont" v-html="item.content"></div>
            <div class="see">
              <div>{{ $t("scientificAchievements.SA_see") }}</div>
              <img src="@/assets/img/checklan.png" alt="" />
            </div>
          </div>
        </div>
        <div class="paging">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="3"
            layout="total,prev,pager,next,jumper"
            :total="count"
            prev-text="上一页"
            next-text="下一页"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <div class="outerTwo" v-if="qualification.length > 0">
      <div class="tipOne">{{ $t("scientificAchievements.SA_tipTwo") }}</div>
      <vue-seamless-scroll
        :data="qualification"
        :class-option="options"
        class="seamless-warp"
      >
        <ul>
          <li v-for="item in qualification" :key="item.index">
            <img :src="item.image" alt="" />
          </li>
        </ul>
      </vue-seamless-scroll>
    </div>

    <!-- <div class="outer" v-if="periodical.length > 0">
      <div class="box">
        <div class="tipOne">{{ $t("scientificAchievements.SA_tipThree") }}</div>
        <div class="library">
          <div
            class="libraryItem"
            v-for="(item, index) in periodical"
            :key="index"
            @click="journalDetails(item)"
          >
            <div class="xian"></div>
            <div class="itemCont">
              <div class="title">{{ item.title }}</div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：技术研发-科研成果
 *
 * @author: 刘嘉鑫
 * @version  V1.0
 * @datecreate: 2022-08-17 17:17
 */
import currentLocation from "@/components/currentLocation.vue";
export default {
  components: {
    currentLocation,
  },
  data() {
    return {
      sibItem: [
        {
          title_zh: "技术研发",
          title_en: "Technology R & D",
          path: "TechnologyRD",
        },
        {
          title_zh: "科研成果",
          title_en: "Scientific research achievements",
          path: "",
        },
      ],
      newList: [],
      count: 0,
      currentPage: 1,
      qualification: [], // 专利资质

      periodical: [], // 期刊
      bannerImg: "",
    };
  },
  computed: {
    options() {
      return {
        // 滚动速度 , 数值越大滚动越快
        step: 0.8,
        // 滚动的数据条数
        limitMoveNum: 2,
        // 鼠标点击停止滚动, 默认true
        hoverStop: true,
        // 按住鼠标停止滚动, 默认true
        openTouch: true,
        // 滚动方向 , 0 向下 , 1 向上 , 2 向左 , 3 向右
        direction: 2,
        // 单步运动停止的高度(默认是0, 无缝不停止的滚动,direction是0或1)
        // 就是滚多高停一下,再滚多高又停一下,停的时间me就是waitTi
        // 这个高度,可以F12审查元素用箭头放在字上面看li的高度是多少
        singleHeight: 32,
        // 单步运动停止的宽度(默认是0, 无缝不停止的滚动,direction是2或3)
        singleWidth: 0,
        // 单步运动停止的时间 (默认值1000ms)
        waitTime: 0,
        // 开启数据实时监控刷新dom
        openWatch: true,
      };
    },
  },
  mounted() {
    this.getScientificResearch();
    this.getQualifications();
    this.getPeriodical();
    this.getBigPicture();
  },
  methods: {
    /**
     * 分页
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.getScientificResearch();
    },

    /**
     * 科研文章
     * 刘嘉鑫
     * 2022-8-24
     */
    getScientificResearch() {
      this.$request({
        url: "/Index/newsList",
        data: {
          type_id: 5, // 分类id
          page: this.currentPage,
        },
      }).then((res) => {
        console.log("科研文章", res);
        this.newList = res.news_list;
        this.count = res.news_count;
        for (let i in this.newList) {
          var re1 = new RegExp("<.+?>", "g"); //匹配html标签的正则表达式，"g"是搜索匹配多个符合的内容
          this.newList[i].content = this.newList[i].content.replace(re1, ""); //执行替换成空字符
        }
      });
    },

    /**
     * 专利资质
     * 刘嘉鑫
     * 2022-8-24
     */
    getQualifications() {
      this.$request({
        url: "/Index/honorList",
      }).then((res) => {
        console.log("专利资质", res);
        this.qualification = res.honor_list.zhuanli;
      });
    },

    /**
     * 文章详情
     * 刘嘉鑫
     * 2022-8-25
     */
    articleDetail(item) {
      this.$router.push({
        path: "/newsCenterDetails",
        query: {
          id: item.id,
        },
      });
    },

    /**
     * 期刊
     * 刘嘉鑫
     * 2022-8-24
     */
    getPeriodical() {
      this.$request({
        url: "/Index/patentList",
      }).then((res) => {
        console.log("期刊", res);
        this.periodical = res.patent_list;
      });
    },

    /**
     * 期刊详情
     * 刘嘉鑫
     * 2022-8-25
     */
    journalDetails(item) {
      this.$router.push({
        path: "/journalDetails",
        query: {
          id: item.id,
        },
      });
    },

    /**
     * 大图
     * 刘嘉鑫
     * 2022-8-25
     */
    getBigPicture() {
      this.$request({
        url: "/Index/imgTwo",
        data: {
          type: 5, // 类型:1=人才培养,2=研发服务,3=专利服务,4=研发条件,5=科研成果,6=研发方向,7=部门设置
        },
      }).then((res) => {
        console.log("大图", res);
        this.bannerImg = res.img;
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.appCont {
  background: #f6f6f6;
}
.outer {
  padding: 0 260px 80px;
}

.bigPicture {
  width: 100%;
  height: 400px;
  margin-bottom: 80px;
}

.box {
  padding: 0 50px;

  .tipOne {
    font-size: 30px;
    font-family: FZFengYaSongS-GB;
    font-weight: bold;
    color: #1a2a60;
    margin-bottom: 60px;
  }

  .newsItem {
    padding: 10px 130px 10px 10px;
    background: #ffffff;
    margin-bottom: 40px;
    display: flex;
    > img {
      width: 180px;
      height: 180px;
      margin-right: 40px;
      object-fit: cover;
    }

    .itemCont {
      display: flex;
      flex-direction: column;
      padding: 20px 0;

      .title {
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #222222;
        margin-bottom: 19px;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        word-break: break-all;
        white-space: normal !important;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .cont {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #888888;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        word-break: break-all;
        white-space: normal !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .see {
        display: flex;
        align-items: center;
        margin-top: auto;
        > div {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #1a2a60;
          margin-right: 10px;
        }
        > img {
          width: 20px;
          height: 12px;
        }
      }
    }
  }

  .newsItem:hover {
    .see {
      > img {
        animation: checkImg 0.7s linear infinite;
        position: relative;
      }
    }
  }
}

.outerTwo {
  background: #1a2a60;
  padding: 80px 0 120px;
  // margin-bottom: 80px;
  .tipOne {
    padding: 0 310px;
    margin-bottom: 70px;
    font-size: 30px;
    font-family: FZFengYaSongS-GB;
    font-weight: bold;
    color: #ffffff;
  }
  .seamless-warp {
    height: 360px;
    overflow: hidden;
    ul {
      display: flex;
      align-items: center;
      margin: 0 !important;
      padding: 0 !important;
      li {
        list-style: none;
        img {
          width: 508px;
          height: 100%;
          margin: 0 80px;
        }
      }
    }
  }
}

.library {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .libraryItem {
    width: 21%;
    height: 134px;
    margin: 0 64px 60px 0;
    cursor: pointer;

    display: flex;
    .xian {
      width: 8px;
      height: 100%;
      background: linear-gradient(135deg, transparent 4px, #1a2a60 0) top left,
        linear-gradient(45deg, transparent 4px, #1a2a60 0) bottom left;
      background-size: 100% 90%;
      background-repeat: no-repeat;
    }

    .itemCont {
      display: flex;
      align-items: flex-start;
      background: #ffffff;
      // padding: 30px 40px 30px 30px;
      width: 100%;
      .title {
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #222222;
        margin: auto auto;
      }
    }
  }

  .libraryItem:nth-child(4n) {
    margin-right: 0 !important;
  }
}
</style>